// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-performer-detail-js": () => import("/opt/build/repo/src/templates/performer-detail.js" /* webpackChunkName: "component---src-templates-performer-detail-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cast-and-crew-js": () => import("/opt/build/repo/src/pages/cast-and-crew.js" /* webpackChunkName: "component---src-pages-cast-and-crew-js" */),
  "component---src-pages-cfp-js": () => import("/opt/build/repo/src/pages/cfp.js" /* webpackChunkName: "component---src-pages-cfp-js" */),
  "component---src-pages-code-of-conduct-js": () => import("/opt/build/repo/src/pages/code-of-conduct.js" /* webpackChunkName: "component---src-pages-code-of-conduct-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sponsor-js": () => import("/opt/build/repo/src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */)
}

